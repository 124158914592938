//Sizes

/*#region Text*/

$standard-font-size: 14px;
$small-font-size: 14px;
$extra-small-font-size: 12px;
$h2-font-size: 18px;
$h1-font-size: 24px;
$affected-train-font: 11px;
$label-font-size: 13px;

/*#endregion */

/*#region Elements*/
/**#region Event Container */
$event-container-width: 360px;
$event-container-max-height: 640px;
$event-container-min-height: 170px;

/*#endregion*/

$nav-bar-height: 50px;

/*#endregion */

/* #region Screen */

$small-screen: 600;
$medium-screen: 1000;
$large-screen: 1200;
/* #endregion*/
