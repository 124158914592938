// Colors
/*#region Blue*/
$blue-20-malibu: #83c2ff;
$blue-25-dodger-blue: #52aaff;
$blue-35-steel-blue: #438db6;
$blue-45-jelly-bean: #23739f;
$blue-55-boston-blue: #3e84c9;
$blue-75-st-tropaz: #252b4a;
/*#endregion */

/*#region Blue-Grey*/
$bluegrey-10-link-water: #dce8f3;
$bluegrey-20-rock-blue: #99bdd1;
$bluegrey-30-pigeon-post: #9dbcda; //Default text colour
$bluegrey-60-lynch: #607387;
$bluegrey-65-east-bay: #3b5873;
$bluegrey-70-fiord: #3f5061;
$bluegrey-80-pickled-bluewood: #24384a; //Used for i.e. nav bar shadow, user menu
$bluegrey-85-mirage-light: #1a2937; //Used for gradient background
$bluegrey-90-mirage: #15202b; //Used for i.e. nav bar, gradient background
$bluegrey-reason-group-title: #354253;
/*#endregion */

/*#region Red*/
$red-10-beauty-bush: #eec9c9;
$red-20-careys-pink: #d297a4;
$red-30-chestnut-red: #cf6363;
$red-40-chestnut-rose: #c85573;
$red-60-amaranth: #f13965;
$red-70-matrix: #a84e4e;
$red-80-burnt-umber: #84273c;
/*#endregion */

/*#region Green*/
$green-30-shamrock: #33c3aa;
$green-60-genoa: #1b7565;
/*#endregion */

/*#region Yellow*/
$yellow-20-gin-fizz: #fffae2;
$yellow-40-jaffa: #f7e797;
$yellow-50-jaffa: #e99549;
$yellow-90-dune: #31302a;
/*#endregion*/

/*#region Misc*/
$version-text: #73889c;
$map-highlight-blue: #2e98ff;
$map-odd-line: #3c5f85;
$map-node-fill: #384755;
$map-node-stroke: #6e849f;
$train-series-blue: #6aa5da;
$input-border-hover: #62809f;
$ghost-border-hover: #74909f;
$primary-button-hover: #2e5a8d;
$changed-announcement-color: #9f6b3b;
$removed-announcement-color: #2e485f;
/*#endregion*/
