@mixin mobile() {
    @media (max-width: 599px) {@content;}
}

@mixin tablet-portrait() {
    @media (max-width: 859px) {@content;}
}

@mixin tablet-landscape() {
    @media (max-width: 1079px) {@content;}
}

@mixin small-desktop() {
    @media (max-width: 1349px) {@content;}
}

@mixin large-desktop() {
    @media (min-width: 1350px) {@content;}
}