/* You can add global styles to this file, and also import other style files */
@import "./assets/styles/colors.scss";
@import "./assets/styles/sizes.scss";
@import "./assets/styles/mediaqueries";
/*Add colors to root to access them from JS/TS*/
:root {
  --data-line-highlight-color: #{$map-highlight-blue};
  --data-line-highlight-dash-color: #{$bluegrey-10-link-water};
  --data-line-active-color: #{$bluegrey-10-link-water};
  --data-line-base-color: #{$bluegrey-80-pickled-bluewood};
  --data-standard-font-size: #{$standard-font-size};
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 100%;
}

html {
  background: $bluegrey-90-mirage;
  @include tablet-portrait {
    height: auto;
    min-height: 100vh;
    background: $bluegrey-85-mirage-light;
  }
}

body {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background: linear-gradient(
    180deg,
    $bluegrey-85-mirage-light 0%,
    $bluegrey-90-mirage 100%
  );
  color: $bluegrey-30-pigeon-post;
  font-size: $standard-font-size;
  font-family: Helvetica;
  font-weight: 500;
  overflow: hidden;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $standard-font-size;
  font-weight: bold;
}

h4 {
  font-size: $small-font-size;
}

hr {
  background: $bluegrey-65-east-bay;
  border-radius: 1px;
  margin-top: 0;
}

button {
  color: $bluegrey-30-pigeon-post;
  border: none;
}

.clear-text-btn-secondary {
  background: transparent;
  font-size: $small-font-size;
  color: $blue-25-dodger-blue;
  border: none;
  display: inline-block;
  position: absolute;
}

.clear-text-btn-primary {
  background: transparent;
  font-size: $standard-font-size;
  color: $bluegrey-30-pigeon-post;
  border: none;
  display: inline-block;
  position: absolute;
}

.clear-btn {
  border: 2px solid $bluegrey-20-rock-blue;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: transparent;
  &:hover {
    border: 2px solid $ghost-border-hover;
  }
  &:focus {
    border: 2px solid $blue-25-dodger-blue;
  }
  margin: 5px;
}

.red-btn-primary {
  background: $red-40-chestnut-rose;
  font-size: $standard-font-size;
  color: $bluegrey-10-link-water;
  border: none;
  display: inline-block;
  height: 35px;
  border-radius: 5px;
  padding: 5px 15px;
  &:disabled {
    background-color: $bluegrey-70-fiord;
    color: $bluegrey-60-lynch;
  }
}

.blue-btn-primary {
  background: $blue-55-boston-blue;
  font-size: $standard-font-size;
  color: $bluegrey-10-link-water;
  border: none;
  display: inline-block;
  height: 35px;
  border-radius: 5px;
  padding: 5px 15px;
  &:disabled {
    background-color: $bluegrey-70-fiord;
    color: $bluegrey-60-lynch;
  }
}

button:focus {
  outline: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}
ul {
  list-style-type: none;
  padding-inline-start: 0;
}

/* #region PrimeNg*/

.p-tooltip.p-component.p-tooltip-left.wide-tooltip.wide-tooltip {
  max-width: unset;
}

/* #region p-orderList */
.p-component {
  font-family: unset !important;
  font-size: unset !important;
}

.p-orderlist {
  .p-orderlist-list {
    border: none !important;
    background: unset !important;
    color: unset !important;
    padding: unset !important;
    border-bottom-right-radius: unset !important;
    border-bottom-left-radius: unset !important;

    margin: unset !important;
    max-height: unset !important;
    min-height: unset !important;
    overflow: unset !important;
    padding: unset !important;

    .p-orderlist-item {
      padding: unset !important;
      margin-bottom: 0.5rem !important;
      border: unset !important;
      color: unset !important;
      background: unset !important;
      transition: unset !important;
    }
  }
  .p-orderlist-controls {
    display: none;
  }
}
/* #endregion */
/* #region p-calendar */

.p-datepicker {
  .p-inputtext.input-field {
    background: $bluegrey-65-east-bay;
    text-indent: 15px;
    padding: 17px 0 3px 0;
    border-radius: 5px;
    height: 45px;
    color: $bluegrey-30-pigeon-post;
  }

  .p-inputtext.input-field.date {
    width: 130px;
    margin-right: 10px;
  }

  .p-inputtext.input-field.time {
    width: 100px;
  }
}
/* #endregion */
/* #region p-dropdown styling*/
.p-dropdown.dropdown {
  width: 100%;
  border-radius: 5px;
  background: $bluegrey-65-east-bay;
  font-family: roboto;
  border: none;
  height: 45px;

  &.p-disabled {
    background: $bluegrey-70-fiord;
    color: $bluegrey-60-lynch;
    opacity: 1;
  }

  .p-dropdown-label {
    height: 45px;
    margin-top: 2px;
    text-indent: 10px;
    color: $bluegrey-30-pigeon-post;
  }
  .p-dropdown-trigger-icon {
    color: $bluegrey-30-pigeon-post;
  }

  ul.p-dropdown-items {
    padding: 10px 0;
  }

  ul.p-dropdown-panel,
  ul.p-dropdown-items {
    background: $bluegrey-65-east-bay;
    border-radius: 0 0 5px 5px;
  }

  li.p-dropdown-item {
    padding: 0 1rem;
    color: $bluegrey-30-pigeon-post;
    font-weight: normal;
  }

  li.p-dropdown-item-group {
    color: $bluegrey-30-pigeon-post;
    background: $bluegrey-65-east-bay;
    padding: 10px 1rem 0 1rem;
    font-size: 12px;
  }
}

.p-select-list-container {
  border-color: $bluegrey-65-east-bay !important;
  background: $bluegrey-65-east-bay;
  margin: -1px;
}

.p-select-option-group {
  color: $bluegrey-30-pigeon-post;
  background: $bluegrey-65-east-bay !important;
}

.p-select-option {
  background: $bluegrey-65-east-bay !important;

  &:hover {
    background: $bluegrey-60-lynch !important;
  }
  &:active {
    background: $bluegrey-30-pigeon-post !important;
  }
}

.p-select-option.p-select-option-selected.p-focus {
  background: $bluegrey-30-pigeon-post !important;
}

.p-select-label {
  color: $bluegrey-30-pigeon-post !important;
}

.dropdown.grouped {
  ul.p-dropdown-items {
    margin-top: 10px;
  }
}

/*#endregion*/
/*#endregion*/

/*#region Scrollbar*/

div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: $bluegrey-80-pickled-bluewood;
}

div::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $bluegrey-85-mirage-light;
}
::-webkit-scrollbar-corner {
  background: $bluegrey-90-mirage;
}

/*#endregion*/

/*#region Spinner, placed here because of use in index.html */
.k-spinner--swirl {
  --spinner-color: var(--color, currentColor);
  --spinner-color-secondary: var(--color-secondary, currentColor);
  --spinner-size: 64px;
  display: inline-flex;
  font-size: var(--spinner-size);
  color: var(--spinner-color);
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  position: relative;
  animation-duration: 2s;
}
.k-spinner--swirl:before {
  top: 0.05em;
  right: 0.05em;
  bottom: 0.05em;
  left: 0.05em;
  animation-duration: 3s;
}
.k-spinner--swirl:after {
  top: 0.135em;
  right: 0.135em;
  bottom: 0.135em;
  left: 0.135em;
  animation-duration: 1.5s;
}
.k-spinner--swirl,
.k-spinner--swirl:after,
.k-spinner--swirl:before {
  border: 0.0333em solid transparent;
  border-radius: 50%;
  border-top-color: currentColor;
  animation-name: k-swirl;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.k-spinner--swirl:after,
.k-spinner--swirl:before {
  content: "";
  position: absolute;
}
@keyframes k-swirl {
  0% {
    transform: rotate(0deg);
  }
  50% {
    color: var(--spinner-color-secondary);
  }
  to {
    transform: rotate(1turn);
  }
}

.wrapper {
  text-align: center;
  margin: 100px 0;
}

.row {
  display: block;
}

/*#endregion*/
